export default {
  CARS: [
    {
      id: "coolray",
      name: "Coolray",
      price: "1 649 990",
      stock_selector: "Coolray, I",
      price_new: "1 349 990",
      spec_ben: "155 000",
      // credit_ben: "185 000",
      // credit_ben_low: "400 000",
      trade_in_high: "235 000",
      trade_in_ben_low: "235 000",
      credit_ben_high: "470 000",
      kasko_ben: "150 000",
      inst_plan: true,
      payment: true,
      configurable: false,
      img_small: true,
      status: 0,
      order: 0,
    },
    // {
    //   id: "tugella",
    //   name: "TUGELLA",
    //   price: "2 869 990",
    //   price_new: "2 469 990",
    //   credit_ben_low: "600 000",
    //   trade_in_high: "350 000",
    //   trade_in_ben_low: "350 000",
    //   credit_ben_high: "550 000",
    //   kasko_ben: "50 000",
    //   inst_plan: true,
    //   payment: true,
    //   configurable: true,
    //   img_small: true,
    // },
    // {
    //   id: "new-tugella",
    //   name: "TUGELLA",
    //   price: "2 124 990",
    //   stock_selector: "Tugella",
    //   price_new: "1 774 990",
    //   spec_ben: "150 000",
    //   credit_ben: "250 000",
    //   credit_ben_low: "400 000",
    //   credit_ben_high: "530 000",
    //   trade_in_high: "280 000",
    //   trade_in_ben_low: "280 000",
    //   salon_benefit: "370 000",
    //   kasko_ben: "150 000",
    //   configurable: true,
    //   no_brand: true,
    //   inst_plan: false,
    //   payment: false,
    //   status: 1,
    //   order: 50,
    // },
    {
      id: "atlas-pro",
      name: "Atlas PRO",
      price: "2 124 990",
      stock_selector: "Atlas Pro",
      price_new: "1 774 990",
      spec_ben: "210 000",
      credit_ben: "470 000",
      // credit_ben_low: "470 000",
      // credit_ben_high: "470 000",
      trade_in_high: "320 000",
      trade_in_ben_low: "320 000",
      kasko_ben: "150 000",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 10,
    },
    {
      id: "monjaro",
      name: "MONJARO",
      price: "1",
      stock_selector: "Monjaro",
      price_new: "",
      spec_ben: "190 000",
      credit_ben: "200 000",
      // credit_ben_low: "200 000",
      // credit_ben_high: "200 000",
      trade_in_high: "250 000",
      trade_in_ben_low: "250 000",
      kasko_ben: "150 000",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 20,
    },
    {
      id: "new-coolray",
      name: "COOLRAY",
      price: "1",
      stock_selector: "Coolray",
      price_new: "",
      spec_ben: "125 000",
      credit_ben: "",
      // credit_ben_low: "",
      // credit_ben_high: "",
      trade_in_high: "260 000",
      trade_in_ben_low: "260 000",
      kasko_ben: "",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 30,
    },
    {
      id: "emgrand",
      name: "EMGRAND",
      price: "1",
      price_new: "",
      stock_selector: "Emgrand",
      spec_ben: "125 000",
      // credit_ben: "200 000",
      // credit_ben_low: "200 000",
      // credit_ben_high: "200 000",
      trade_in_high: "263 000",
      trade_in_ben_low: "263 000",
      kasko_ben: "",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 40,
    },
    {
      id: "new-atlas",
      name: "NEW ATLAS",
      price: "1",
      price_new: "",
      stock_selector: "Atlas",
      spec_ben: "50 000",
      // credit_ben: "150 000",
      // credit_ben_low: "150 000",
      // credit_ben_high: "150 000",
      trade_in_high: "250 000",
      trade_in_ben_low: "250 000",
      kasko_ben: "",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 50,
    },
    // {
    //   id: "belgee-x50",
    //   name: "BELGEE X50",
    //   price: "1",
    //   price_new: "",
    //   stock_selector: "X50",
    //   credit_ben: "135 000",
    //   trade_in_high: "185 000",
    //   trade_in_ben_low: "185 000",
    //   configurable: true,
    //   img_small: true,
    //   no_brand: true,
    //   status: 1,
    //   order: 30,
    // },
    {
      id: "okavango",
      name: "Okavango",
      price: "1",
      price_new: "",
      stock_selector: "Okavango",
      // spec_ben: "50 000",
      // credit_ben: "250 000",
      // credit_ben_low: "250 000",
      // credit_ben_high: "250 000",
      trade_in_high: "373 000",
      trade_in_ben_low: "373 000",
      // kasko_ben: "",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 60,
    },
    {
      id: "preface",
      name: "Preface",
      price: "1",
      price_new: "",
      stock_selector: "Preface",
      // spec_ben: "50 000",
      // credit_ben: "150 000",
      // credit_ben_low: "150 000",
      // credit_ben_high: "150 000",
      trade_in_high: "258 000",
      trade_in_ben_low: "258 000",
      // kasko_ben: "",
      individual_ben: "+50 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 70,
    },
    {
      id: "cityray",
      name: "Cityray",
      price: "1",
      price_new: "",
      stock_selector: "Cityray",
      // spec_ben: "50 000",
      credit_ben: "200 000",
      // credit_ben_low: "200 000",
      // credit_ben_high: "200 000",
      trade_in_high: "190 000",
      trade_in_ben_low: "190 000",
      // kasko_ben: "",
      individual_ben: "+30 000 ₽ при покупке зимних шин",
      configurable: true,
      img_small: true,
      no_brand: true,
      status: 1,
      order: 80,
    },
  ],
};
