<template>
  <div class="relative w-full ">
    <div
      class="cursor-pointer absolute top-4 right-4 w-8 h-8 before:content-[''] before:absolute before:w-8 before:h-px before:bg-black before:rotate-45 after:content-[''] after:absolute after:w-8 after:h-px after:bg-black after:-rotate-45 z-30"
      @click="modalStore.hideModal"
    ></div>
    <div class="bg-white rounded-md z-10 relative overflow-y-auto max-h-[768px] md:h-auto xl:max-h-[768px] 2xl:overflow-auto">
      <div class="container py-4 relative">
        <h2 class="text-center uppercase text-black font-semibold text-3xl mb-4">Подробные условия акции</h2>
        <div class="grid gap-5 text-[14px] space-y-4">
          
<p><b>
    Максимальная выгода
</b></p>
<p>
    Выгода в размере 1 000 000 рублей указана для автомобилей Geely Atlas Pro в комплектации Flagship и состоит из выгоды в размере 110 000 рублей, применяемой к цене автомобиля, дополнительной выгоды от салона 100 000 рублей, при покупке в кредит в размере 470 000 рублей и выгоды по программе «Трейд-ин» в размере до 320 000 рублей (при условии сдачи автомобиля стоимостью от 1 000 000 р.) . Программа действует с 01.02.2025 по 28.02.2025 г
</p>
<p><b>
    Гарантия 5 лет
</b></p>
<p>
    Гарантия распространяется на 5 лет или на 150&nbsp;000 км. пробега (в зависимости от того, что наступит ранее), за исключением отдельных комплектующих автомобиля. Подробности уточняйте по тел.8 800&nbsp;200 02 89 (звонок по РФ бесплатный) либо у сотрудников дилерских центров «Geely».<br>
    &nbsp;
</p>

<p><b>
    Специальная цена&nbsp;
</b></p>

<p>
    для&nbsp;AtlasPro,&nbsp;Monjaro
</p>
<p>
    Максимальная цена перепродажи, с учетом применимых выгод состоит из выгоды, применяемой к цене автомобиля, выгоды по программе «Трейд-ин», выгоды при покупке с использованием кредитных средств Банка и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой.
</p>
<p>
    для&nbsp;AtlasPro,&nbsp;Atlas
</p>
<p>
    Максимальная цена перепродажи, с учетом применимых выгод состоит из выгоды, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой.
</p>
<p>
    для&nbsp;Cityray
</p>
<p>
    Максимальная цена перепродажи, с учетом применимых выгод состоит из выгоды по программе «Трейд-ин», выгоды при покупке с использованием кредитных средств Банка и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой.
</p>
<p>
    для&nbsp;Coolray,&nbsp;Okavango,&nbsp;Emgrand,&nbsp;Preface
</p>
<p>
    Максимальная цена перепродажи, с учетом применимых выгод состоит из выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой.&nbsp;
</p>
<p><b>
    Atlas&nbsp;Pro
</b></p>

<p><b>
    Максимальная выгода
</b></p>
<p>
    до 593 000 рублей на&nbsp;Atlas&nbsp;Pro (специальная выгода + выгода в трейд-ин (лояльный/специальный) + скидка при покупке шин)
</p>
<p>
    Выгода в размере 593 000 рублей указана для автомобилей «Geely&nbsp;Atlas&nbsp;Pro» (все комплектации, кроме Comfort 2WD и Luxury 2WD) и состоит из выгоды в размере 110 000 рублей, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» в размере 453 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей.
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 453 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>

<p><b>
    Выгода при покупке в Трейд-Ин&nbsp;
</b></p>
<p>
    на&nbsp;Atlas&nbsp;Pro до 453&nbsp;000&nbsp;
</p>
<p>
    Выгода до 270&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely Atlas&nbsp;Pro» (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Atlas&nbsp;Pro». Единовременная&nbsp;и разовая скидка до 270 000 рублей на «Geely Atlas&nbsp;Pro» (в комплектациях: Luxury 4WD, Flagship, Flagship+) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>

<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Atlas&nbsp;Pro», с учетом применимых выгод состоит из выгоды, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely».&nbsp;
</p>


<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;AtlasPro».&nbsp;
</p>
<p><b>
    Coolray
</b></p>

<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 290&nbsp;000 рублей на&nbsp;Coolray (выгода при покупке трейд-ин (лояльный/специальный) + скидка при покупке шин)
</p>
<p>
    Выгода в размере 290 000 рублей указана для автомобилей «Geely&nbsp;Coolray» (все комплектации) и состоит из выгоды по программе «Трейд-ин» в размере 260 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей. Условия настоящей программы не сочетаются с условиями программы Специальная выгода.
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 260 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>

<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на&nbsp;Coolray до 260&nbsp;000 рублей<br>
    Выгода 200&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely&nbsp;Coolray» (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Coolray». Единовременная&nbsp;и разовая скидка 260 000 рублей на все комплектации, предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Условия настоящей программы не сочетаются с условиями программы Специальная выгода.
</p>


<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Coolray», с учетом применимых выгод состоит из состоит из выгоды по программе «Трейд-ин» в размере 260 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей. Условия настоящей программы не сочетаются с условиями программы Специальная выгода.&nbsp;
</p>
<p><b>
    Специальная выгода
</b></p>
<p>
    Выгода в размере 125&nbsp;000 рублей, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Выгода по программе предоставляется при приобретении конечным покупателем автомобиля за счет собственных средств. Условия настоящей программы не сочетаются с условиями других программ, кроме Программы «Специальное предложение на зимние шины».&nbsp;
</p>

<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Coolray». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>

<p><b>
    Emgrand
</b></p>

<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 280&nbsp;000 рублей на&nbsp;Emgrand&nbsp;(выгода при покупке в трейд-ин (лояльный/специальный) + скидка при покупке шин)
</p>
<p>
    Выгода в размере 280 000 рублей указана для автомобилей «Geely&nbsp;Emgrand» (все комплектации) и состоит из выгоды по программе «Трейд-ин» в размере 150 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей. Условия настоящей программы не сочетаются с условиями программы Специальная выгода.
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 250 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>
<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на&nbsp;Emgrand до 250&nbsp;000 рублей<br>
    Выгода 150&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely&nbsp;Emgrand» (далее – «Программа»). Программа действительна на действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely&nbsp;Emgrand». Единовременная&nbsp;и разовая скидка 250 000 рублей на все комплектации, предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»). Условия настоящей программы не сочетаются с условиями программы Специальная выгода.<br>
    &nbsp;
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Emgrand», с учетом применимых выгод состоит из состоит из выгоды по программе «Трейд-ин» в размере 250 000 рублей и скидки на покупку зимних шин в размере 30 000 рублей. Условия настоящей программы не сочетаются с условиями программы Специальная выгода. Предложение действует с 02.02.2025 г. по 28.02.2025 г.<br>
    &nbsp;
</p>
<p><b>
    Специальная выгода
</b></p>
<p>
    Выгода в размере 125&nbsp;000 рублей, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Выгода по программе предоставляется при приобретении конечным покупателем автомобиля за счет собственных средств. Условия настоящей программы не сочетаются с условиями других программ. Программа действует с 02.02.2025 г. по 28.02.2025г.
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Emgrand». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p><b>
    Monjaro
</b></p>
<p><b>
    Максимальная выгода
</b></p>
<p>
    до 670&nbsp;000 рублей на Monjaro (специальная выгода + выгода в трейд-ин (лояльный/специальный) + выгода в кредит + скидка при покупке шин)
</p>
<p>
    Выгода в размере 670 000 рублей указана для автомобилей «Geely Monjaro» (все комплектации) и состоит из выгоды в размере 190 000 рублей, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» в размере 250 000 рублей и выгоды при покупке с использованием кредитных средств Банка в размере 200 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей.&nbsp;
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 250 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    При приобретении конечным покупателем Автомобиля с использованием кредитных средств Банка, по условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на сумму не менее 200 000 рублей. Выгода по Программе предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке.&nbsp;
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>
<p><b>
    Выгода при покупке в Трейд-Ин&nbsp;
</b></p>
<p>
    на Monjaro до 250&nbsp;000 рублей
</p>
<p>
    Выгода до 250&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely Monjaro» (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Monjaro». Единовременная&nbsp;и разовая скидка до 250 000 рублей на «Geely Monjaro» (все комплектации) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Выгода при покупке в кредит&nbsp;
</b></p>
<p>
    на Monjaro до 200&nbsp;000 рублей
</p>
<p>
    При приобретении Конечным покупателем Автомобиля с использованием кредитных средств Банка по условиям программы, Дилер снижает Розничную цену Нового Автомобиля для Конечного покупателя.
</p>
<p>
    *Выгода по программе «Geely Finance» предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке для «Geely Monjaro» (все комплектации) в размере 200 000 рублей.&nbsp;
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Monjaro», с учетом применимых выгод состоит из выгоды, применяемой к цене автомобиля, выгоды по программе «Трейд-ин», выгоды при покупке с использованием кредитных средств Банка и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely». Предложение действительно с 02.02.2025 г. по 28.02.2025 г.&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальная выгода
</b></p>
<p>
    Выгода в размере 190&nbsp;000 рублей, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Программа действует с 02.02.2025 г. по 28.02.2025г.
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Monjaro». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p><b>
    Atlas
</b></p>
<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 343 000 рублей на&nbsp;Atlas (специальная выгода + выгода в трейд-ин (лояльный/специальный) + скидка при покупке шин)
</p>
<p>
    Выгода в размере 380 000 рублей указана для автомобилей «Geely&nbsp;Atlas» (все комплектации) и состоит из выгоды в размере 50 000 рублей, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» в размере 263 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей.&nbsp;
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 263 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>
<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на&nbsp;Atlas&nbsp;до 263&nbsp;000 рублей<br>
    Реклама. ООО «__Юр название ДЦ__». Не оферта. Количество автомобилей ограничено. ООО «ДЖИЛИ-МОТОРС» вправе изменить сроки и условия Программы. Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ ________ (Сайт дилера: www.geely-dealer.com) либо по тел.: ТЕЛ ДЦ.<br>
    Выгода 263&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely&nbsp;Atlas» (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Новый&nbsp;Atlas». Единовременная&nbsp;и разовая скидка 200 000 рублей (все комплектации) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальная выгода
</b></p>
<p>
    Выгода в размере 50&nbsp;000 рублей, применяемая к цене автомобиля, предоставляется Дилером покупателю от розничной цены автомобиля. Программа действует с 02.02.2025 г. по 28.02.2025г.
</p>
<p>
    &nbsp;
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Atlas», с учетом применимых выгод состоит из, применяемой к цене автомобиля, выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely». Предложение действительно с 02.02.2025 г. по 28.02.2025 г.&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Atlas». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p><b>
    Okavango
</b></p>
<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 403 000 рублей на Okavango (выгода в трейд-ин (лояльный/специальный) + скидка при покупке шин)
</p>
<p>
    Выгода в размере 403 000 рублей указана для автомобилей «Geely Okavango» (все комплектации) и состоит из выгоды в размере выгоды по программе «Трейд-ин» в размере 373 000 рублей и скидки на покупку зимних шин в размере 30&nbsp;000 рублей.&nbsp;
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 373 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>
<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на Okavango до 373&nbsp;000 рублей<br>
    Выгода 200&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely Okavango» (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Okavango». Единовременная&nbsp;и разовая скидка 373 000 рублей (все комплектации) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely Okavango», с учетом применимых выгод состоит из выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely». Предложение действительно с 02.02.2025 г. по 28.02.2025 г.&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 30 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Okavango». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p><b>
    Preface
</b></p>
<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 308 000 рублей на&nbsp;Preface&nbsp;(выгода в трейд-ин (лояльный/специальный) + выгода в кредит + скидка при покупке шин)
</p>
<p>
    Выгода в размере 308 000 рублей указана для автомобилей «Geely&nbsp;Preface» (в комплектации&nbsp;Flagship) и состоит из выгоды в размере выгоды по программе «Трейд-ин» в размере 258 000 рублей и скидки на покупку зимних шин в размере 50&nbsp;000 рублей.&nbsp;
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 258 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 50 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>
<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на&nbsp;Preface&nbsp;до 258&nbsp;000 рублей<br>
    Выгода 200&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely Preface» (в комплектации Flagship) (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Preface». Единовременная&nbsp;и разовая скидка 258 000 рублей (все комплектации) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).&nbsp;
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Preface», с учетом применимых выгод состоит из выгоды по программе «Трейд-ин» и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely». Предложение действительно с 02.02.2025 г. по 28.02.2025 г.&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 50 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Preface». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p><b>
    Cityray
</b></p>
<p><b>
    Максимальная выгода&nbsp;
</b></p>
<p>
    до 420 000 рублей на&nbsp;Cityray&nbsp;(выгода в трейд-ин (лояльный/специальный) + выгода в кредит + скидка при покупке шин)
</p>
<p>
    Выгода в размере 420 000 рублей указана для автомобилей «Geely&nbsp;Cityray» (все комплектации, кроме&nbsp;Comfort) и состоит из выгоды в размере выгоды по программе «Трейд-ин» в размере 190 000 рублей, выгоды при покупке с использованием кредитных средств Банка в размере 200 000 рублей и скидки на покупку зимних шин в размере 50&nbsp;000 рублей.&nbsp;
</p>
<p>
    Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely». Единовременная скидка 190 000 рублей на указанные выше автомобили предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в трейд-ин автомобиля марки «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).
</p>
<p>
    При приобретении конечным покупателем Автомобиля с использованием кредитных средств Банка, по условиям Программы, Дилер снижает розничную цену нового Автомобиля для конечного покупателя на сумму не менее 200 000 рублей. Выгода по Программе предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке.&nbsp;
</p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 50 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей марки «Geely».
</p>

<p><b>
    Выгода при покупке в Трейд-Ин
</b></p>
<p>
    на&nbsp;Preface&nbsp;до 190&nbsp;000 рублей<br>
    Выгода 200&nbsp;000 рублей достигается по программе «Трейд-ин». Программа Трейд-ин - возможность продажи автомобиля с пробегом, принадлежащего покупателю, в зачет стоимости нового автомобиля «Geely&nbsp;Cityray» (в комплектации&nbsp;Flagship) (далее – «Программа»). Программа действует с 02.02.2025 г. по 28.02.2025 г. на новые автомобили «Geely Cityray». Единовременная&nbsp;и разовая скидка 190 000 рублей (все комплектации, кроме&nbsp;Comfort) предоставляется Дилером покупателю от розничной цены Автомобиля, приобретаемого по Программе, при сдаче в Трейд-ин автомобилей «Geely» или другой популярной марки (список марок уточняйте в дилерских центрах «Geely»).&nbsp;<br>
    &nbsp;
</p>

<p><b>
    Выгода при покупке в кредит
</b></p>
<p>
    на&nbsp;Preface&nbsp;до 200&nbsp;000 рублей
</p>
<p>
    При приобретении Конечным покупателем Автомобиля с использованием кредитных средств Банка по условиям программы, Дилер снижает Розничную цену Нового Автомобиля для Конечного покупателя.
</p>
<p>
    *Выгода по программе «Geely Finance» предоставляется при покупке нового автомобиля «Geely» в кредит в любом банке для «Geely&nbsp;Cityray» (все комплектации, кроме&nbsp;Comfort) в размере 200 000 рублей.
</p>
<p><b>
    Специальная цена
</b></p>
<p>
    Максимальная цена перепродажи автомобиля «Geely&nbsp;Cityray», с учетом применимых выгод состоит из выгоды по программе «Трейд-ин», выгоды при покупке с использованием кредитных средств Банка и скидки на покупку зимних шин. Настоящее предложение, включая любые сведения о предоставляемой выгоде, носит исключительно информационный характер, не является офертой, подробности у официального дилера «Geely». Предложение действительно с 02.02.2025 г. по 28.02.2025 г.&nbsp;<br>
    &nbsp;
</p>
<p><b>
    Специальное предложение на зимние шины
</b></p>
<p>
    Специальное предложение на зимние шины – это единовременная и разовая скидка в размере 50 000 рублей, которая предоставляется дилером покупателю на покупку зимних шин, приобретаемых по программе, при приобретении автомобилей «Geely&nbsp;Cityray». Программа действует с 02.02.2025 г. по 28.02.2025 г.&nbsp;
</p>
<p>
    <b>*Рассрочка 0,01%</b>
</p>
<p>
    Предложение распространяется на новые автомобили марки «Geely». Указанная процентная ставка 0,01% в год достигается при следующих условиях кредитования по кредитному продукту: первоначальный взнос от 40%, сумма кредита от 500 000 руб. срок кредитования 12 мес. Условия и тарифы могут быть изменены банком в одностороннем порядке. Банк в праве отказать в выдаче автокредита. Обеспечение по кредиту - залог приобретаемого автомобиля. Предложение действует с 03.02.2025 г. по 28.02.2025 г. Кредит предоставляется ПАО «Совкомбанк». Генеральная лицензия Банка России №963 от 5 декабря 2014 г.
</p>
<p>
    Реклама. ООО «АТЦ Кунцево». Не оферта. Программа действует с 02.02.2025 г. по 28.02.2025 г. Количество автомобилей ограничено. Подробные условия и наличие автомобилей уточняйте у менеджеров ДЦ Джили Кунцево&nbsp;www.geely-kuntsevo.ru &nbsp;либо по тел.: +7(495)933-40-33
</p>

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useModalStore } from "@/stores/modalStore";
import { useMessageStore } from "@/stores/messageStore";
import { storeToRefs } from 'pinia'
import { useDealerStore } from "@/stores/dealerStore";

const modalStore = useModalStore()
const messageStore = useMessageStore()
const dealerStore = useDealerStore();
const { modalMessage } = storeToRefs(modalStore)
const { getDealerById } = storeToRefs(dealerStore);
</script>

<style scoped>
article {
  @apply space-y-2
}
h3 {
  font-size: 16px;
  font-weight: 700;
}
h4 {
  font-size: 14px;
  font-weight: 500;
}
a[href] {
  /* text-decoration: underline; */
  color: #002E5D;
}
</style>